.webGL-legend-scrollbar {
  width: 160px !important;
}
.bin-legend-header {
  top: 0;
}
.bin-legend-header .bin-select {
  margin-right: 10px;
}
.bin-legend-header > span {
  margin-right: 10px;
}
.notificationSection {
  font-weight: bold;
  color: red;
}
.warningNotificationPanel {
  background-color: #ffdfa3;
}
.criticalNotificationPanel {
  background-color: #f8b8b8;
}
.site-collapse-custom-panel .bin-temperature.selected {
  border: 2px solid yellow;
}
.webGL-legend {
  max-width: 220px;
  margin: 10px;
}
.webGL-legend > legend {
  margin-bottom: 0;
}
.webGL-legend .ant-tree {
  font-size: 12px;
}
.webGL-legend .ant-tree li {
  padding: 0;
}
.webGL-legend .ant-tree li span.ant-tree-switcher {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.webGL-legend .ant-tree li .ant-tree-node-content-wrapper {
  height: 20px;
  line-height: 16px;
}
.webGL-legend .ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: 100%;
}
.webGL-legend .ant-tree li ul {
  padding: 0 0 0 8px;
}
.webGL-legend .ant-tree-switcher {
  display: none;
}
.webGL-legend .color_square {
  border-radius: 4px;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 12px;
}
.webGL-legend .color_square.open {
  background-color: white;
  border: solid 1px black;
}
.webGL-legend .color_square.closed {
  background-color: black;
}
.webGL-legend .tree-switch {
  margin-right: 5px;
}
#text {
  background-color: transparent;
  /* needed because webgl-tutoraisl.css sets canvas bg color to white */
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 10;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  display: none;
}
