
body {
    background-color: #f0f2f5;
    font-family: Helvetica;
}

.app-header {
    background: #fff;
    padding: 0;
    z-index: 10;
    &.public {
        text-align: center;
        // border-bottom: 1px solid @border-color-split;
    }
}

#root > .ant-layout {
    min-height: calc(100vh - 64px);
}

.ant-layout-header {
    overflow: hidden;
}

.ant-layout-header .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px 0 16px;
    cursor: pointer;
    transition: color .3s;
}

.ant-layout-header .trigger:hover {
    color: #1890ff;
}

/*.ant-layout-header .logo {
    height: 32px;
    background: rgba(255,255,255,.2);
    margin: 16px;
}*/

.content-container {
    padding: 18px;
}

.content-head {
    padding: 16px 20px;
}

.content-head h2 {
    margin-bottom: 0;
}

.layout-fullscreen {
    height: 100vh;

    > .ant-layout-sider {
        overflow: hidden;
        flex: 0 0 240px;
        max-width: 240px;
        min-width: 240px;
        width: 240px;

        > .ant-layout-sider-children {
            flex-direction: column;

            > .logo-container {
                transition: .3s;
                width: 240px;
                display: flex;
                align-items: center;

                > .logo-text {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    font-size: 20px;
                    font-family: fantasy;
                    margin-left: 10px;
                }
            }

            .scrollbar-sidenav {
                position: absolute !important;
                top: 64px !important;
                bottom: 0 !important;
                height: auto !important;
            }

            .ant-menu {
                flex: 1;
                // position: absolute;
                // top: 64px;
                // bottom: 0;
                // overflow: hidden;
            }

            // .ant-menu:hover {
            //     overflow-y: auto;
            // }
        }

        &.ant-layout-sider-collapsed {
            > .ant-layout-sider-children {
                > .logo-container {
                    position: absolute;
                    //width: 70px; // old image without text
                    width: 55px;
                }
            }
        }
    }

    > .ant-layout {

        > .ant-layout-header {
            position: absolute;
            left: 195px;
            right: 0;
            top: 0;
            transition: .3s;
        }

        > .ant-layout-header.nonadmin {
            position: absolute;
            left: 0px;
            right: 0;
            top: 0;
            transition: .3s;
            background: #fff;
            padding: 0;
            z-index: 10;
            &.public {
                text-align: center;
                // border-bottom: 1px solid @border-color-split;
            }

            .logo-container {
                margin: -2px 4px 0px 4px;
                padding-left: 2px;
                transition: .3s;
                width: 240px;
                display: flex;
                align-items: center;

                > .logo-text {
                    display: flex;
                    flex: 1;
                    line-height: 30px;
                    flex-direction: column;
                    font-size: 20px;
                    font-family: fantasy;
                    margin-left: 10px;
                }
            }
        }

        .ant-layout-content.page-content {
            position: absolute;
            left: calc(195px + 18px);
            right: 18px;
            top: calc(64px + 18px);
            bottom: 18px;
            transition: .3s;
        }

        .ant-layout-content.page-content-nonadmin {
            position: absolute;
            left: 18px;
            right: 18px;
            top: calc(64px + 18px);
            bottom: 18px;
            transition: .3s;
        }
    }

    > .ant-layout-sider-collapsed {
        + .ant-layout {
            > .ant-layout-header {
                left: 70px;
            }

            > .ant-layout-content {
                left: calc(70px + 18px);
            }
        }
    }
}

.width-max-700 {
    max-width: 700px;
}

/*.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane {
    padding: 0 24px 24px;
}*/

// .ant-tabs.tabbar-border-top {
//     border-top: 1px solid @border-color-split;
// }

.ant-breadcrumb {
    display: inline;
    margin: 16px 0;
    font-size: 20px !important; // Need this because tinyColor.less overrides the default for some reason...
}

.ant-breadcrumb .anticon {
    font-size: 20px !important; // Need this because tinyColor.less overrides the default for some reason...
}

.ant-breadcrumb-link > a .anticon {
    margin-right: 5px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    word-break: normal;
}

.ant-empty-image {
    display: none;
}

.no-margin {
    margin: 0;
}

.no-border {
    border: none !important;
    border-radius: 0 !important;
}

.no-click {
    cursor: default !important;
}

.no-select {
    user-select: none;
}

// .border {
//     border: 1px solid @border-color-split !important;
// }

// .border-top {
//     border-top: 1px solid @border-color-split !important;
// }

// .border-right {
//     border-right: 1px solid @border-color-split !important;
// }

// .border-bottom {
//     border-bottom: 1px solid @border-color-split !important;
// }

// .border-left {
//     border-left: 1px solid @border-color-split !important;
// }

.border-rounded {
    border-radius: 4px !important;
}

.height-full {
    height: 100%;
}

.height-full > .ant-spin-nested-loading {
    height: 100%;
}

.height-full > .ant-spin-nested-loading > .ant-spin-container {
    height: 100%;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    max-height: 300px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1000;
}

.dropdown.relative .dropdown-content {
    position: relative;
}

.float-right,
.pull-right {
    float: right;
}

.float-left,
.pull-left {
    float: left;
}

.content-background {
    background-color: #fff;
}

.content-main {
    background-color: #fff;
    margin: 0;
    min-height: 280px;
    padding: 18px;
}

.content-pad {
    padding: 18px;
}

.content-pad.ant-layout-sider-collapsed {
    padding: 0;
}

.scroll-auto {
    overflow: auto;
}

.ant-spin-nested-loading > div > .ant-spin {
    max-height: none;
}

.ant-form-item:not(:last-child) {
    margin-bottom: 14px;
}

.ant-form-item:last-child {
    margin-bottom: 0;
}

.ant-form-item.no-margin {
    margin: 0;
}

.ant-form.no-margin .ant-form-item {
    margin: 0;
}

.ant-form legend {
    margin-bottom: 14px;
}

.ant-list-pad .ant-list-item {
    padding-left: 10px;
    padding-right: 10px;
}

.ant-list-clickable .ant-list-item:hover {
    background-color: #fff9f0;
    cursor: pointer;
    transition: all .3s;
}

.ant-list-clickable .ant-list-item.selected {
    background-color: #ffe8cc;
}

// .text-highlight {
//     color: @primary-color;
// }

.content-pad-rows {
    > .ant-row, > .ant-row-flex {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

// .ant-btn {
//     -moz-box-shadow:    inset 0 0 2px #000000;
//     -webkit-box-shadow: inset 0 0 2px #000000;
//     box-shadow:         inset 0 0 2px #000000;
// }

// .ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not([disabled]) {
//     color: #0A70A6;
//     border-color: #0A70A6;
// }

// .ant-btn:not(.ant-btn-primary):not(.ant-btn-icon-only):not(.ant-btn-danger) {
//     box-shadow: 0 2px 0 rgba(0,0,0,.015), inset 0 0 0 1px rgba(82,104,129,1);
// }

.ant-btn-danger {
    background-color: #fff;
    border-color: #f5222d;
    color: #f5222d;
}

// .ant-btn-danger {
//     box-shadow: 0 2px 0 rgba(0,0,0,.015), inset 0 0 0 1px #f5222d;
// }

.ant-btn-danger:hover {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    // box-shadow: none;
}

// .ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):not:hover{
//     border-color: #f0964d !important;
//     color: #f0964d !important;
// }
// .ant-btn:not(.ant-btn-primary):not(.ant-btn-danger):focus {
//     color: #0A70A6;
//     border-color: #0A70A6;
// }

.button-group button:not(:last-child) {
    margin-right: 10px;
}

.ant-empty-normal {
    margin: 8px 0;
}

.ant-form.ant-form-horizontal.form-flex {
    > .ant-row.ant-form-item,
    > .ant-row > .ant-col > .ant-row.ant-form-item {
        display: flex;
        flex-direction: row;

        > .ant-form-item-label {
            margin-right: 5px;
        }

        > .ant-form-item-control-wrapper {
            flex: 1;
            margin-left: 5px;
        }
    }
}

.ant-form.ant-form-inline.form-flex {
    display: flex;

    >.ant-row.ant-form-item {
        display: flex;
        flex: 1;

        >.ant-form-item-label {
            > label {
                padding-left: 10px;
            }
        }

        >.ant-form-item-control-wrapper {
            flex: 1;
            margin-left: 5px;
        }
    }
}

.labelSize-variation(@sizes, @index) when (@index > 0) {
    .labelSize-variation(@sizes; (@index - 1)); // decrement.

  @size : extract(@sizes, @index);

  .ant-form.label-@{size} {
    > .ant-form-item,
    > .ant-row > .ant-col > .ant-form-item {
        > .ant-form-item-label {
            width: @size*1px;
        }
    }
  }
}
@labelSizes : 80, 100, 120, 140, 160;
.labelSize-variation(@labelSizes; length(@labelSizes));


.formSize-variation(@sizes, @index) when (@index > 0) {
    .formSize-variation(@sizes; (@index - 1)); // decrement.

  @size : extract(@sizes, @index);

  .ant-form.max-@{size} {
    max-width: @size*1px;
}
}
@maxFormSizes : 400, 800, 1000, 1200, 1600;
.formSize-variation(@maxFormSizes; length(@labelSizes));

.ant-form-inline .ant-form-item {
    margin-right: 0;
}

.ant-form-item.flex-row > .ant-form-item-control-wrapper {
    flex: 1;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children,
.ant-form-inline .ant-form-item.flex-row,
.flex-row {
    display: flex;
    flex-direction: row;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-control,
.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > .flex,
.flex-row > .flex {
    flex: 1;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *,
.flex-row > * {
    margin: 0 5px;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *:first-child,
.flex-row > *:first-child {
    margin-left: 0;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *:last-child,
.flex-row > *:last-child {
    margin-right: 0;
}

.ant-row.ant-form-item .ant-form-item-control-wrapper.flex-row .ant-form-item-children > *,
.flex-row > .ant-btn.ant-btn-sm {
    margin: 0 2px;
}

.flex-wrap {
    flex-wrap: wrap;
}

.pad-children > *:not(:last-child) {
    margin: 0 5px 0 0;
}

.space-evenly {
    justify-content: center;
}

.form-label {
    line-height: 32px;
}

.form-label-md {
    width: 150px;
}

// .error {
//     color: @error-color;
// }

.highlight {
    color: #f50;
}

.app-spinner {
    .ant-spin {
        position: absolute;
        top: calc(50% - 29px);
        left: calc(50% - 29px);
    }

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: #EFEFEF44;
}

.ant-select-dropdown-menu-item .option-right {
    position: absolute;
    color: #999;
    right: 16px;
}

/* use css to set position of modal */
.vertical-center-modal {
    text-align: center;
    white-space: nowrap;
}

.vertical-center-modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.vertical-center-modal .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    text-align: left;
}

/*
// Use flex which not working in IE
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ant-modal {
  top: 0;
}
*/

.ant-table-placeholder {
    z-index: auto;
}

.ant-table.ant-table-small .ant-table-placeholder {
    padding: 8px;
}

.ant-table-wrapper.compact .ant-table-middle {
    th {
        padding: 8px !important;
    }

    td {
        padding: 6px 4px !important;
    }
}

.hide-expand .ant-table-row-expand-icon {
    visibility: hidden;
  }

.ant-table tbody tr th {
    padding: 8px;
}

.ant-table .ant-table-full-width-header .ant-table-header-column {
    width: 100%;
}

.ant-table-expanded-row .ant-table-thead > tr > th {
    background: none;
}

.ant-table-thead > tr.ant-table-row-hover > td,
.ant-table-tbody > tr.ant-table-row-hover > td {
    background: none;
}

.ant-table-wrapper .ant-form-item-control {
    line-height: inherit;
}

.ant-table-scroll .ant-table-body {
    overflow-x: auto !important;
}

table.headers-align-right th {
    text-align: right;
}

.ant-table-filter-dropdown {
    min-width: 0;
    text-align: center;
}

tr.editable-row td {
    transition: none;
}


#body {
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance:textfield;
    }

    div.short,
    .ant-input.short,
    .ant-input-number.short,
    .ant-input-group-wrapper.short {
        max-width: 130px;
    }
}

.ant-input.right input,
.ant-input-number.right input {
    text-align: right;
}

.ant-divider.ant-divider-horizontal.thin {
    margin-top: 3px;
    margin-bottom: 3px;
}

.lineDetail tr th {
    font-weight: 600;
}

.lineDetail.even {
    background-color: fade(#000, 1%);
}

.lineDetail.odd {
    background-color: fade(#000, 4%);
}

.lineDetail .ant-table-tbody > tr:hover > td {
    background-color: unset;
}

.lineDetail:hover {
    border-color: #a7a7a7 !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.table-row-icon {
    font-size: 24px;
}

.nested-table {
    /*td.editable-cell {
        padding: 0 !important;

        .ant-input-number {
            width: 100% !important;
        }

        .ant-input-number-input {
            width: 100%;
        }
    }*/
    tr.ant-table-expanded-row {
        background: none;
    }
    .ant-table-expand-icon-th, .ant-table-row-expand-icon-cell {
        padding: 0 3px 0 5px !important;
        min-width: 17px !important;
        width: 17px !important;
    }

    @normal-tbl-bg: #ffffff;
    @alt-tbl-bg: #f9f9f9;
    @detail-tbl-bg: #f4fbff;

    .ant-table-thead > tr > th {
        background-color: rgba(0,0,0,0.02);
    }

    col {
        &.stripe-column {
            background-color: @detail-tbl-bg !important;
        }
    }

    :not(.even) > td {
        &.stripe-column {
            background-color: @detail-tbl-bg !important;
        }
    }
    &:not(.striped){
        .ant-table-expanded-row .ant-table-expanded-row > td,
        .ant-table-expanded-row .ant-table-expanded-row .ant-table-expanded-row .ant-table-expanded-row > td {
            background-color: @normal-tbl-bg;

        }
        .ant-table-expanded-row .ant-table-expanded-row > td:first-child,
        .ant-table-expanded-row .ant-table-expanded-row .ant-table-expanded-row .ant-table-expanded-row > td:first-child {
            background-color: @alt-tbl-bg;

        }
        .ant-table-expanded-row > td,
        .ant-table-expanded-row .ant-table-expanded-row .ant-table-expanded-row > td {
            background-color: @alt-tbl-bg;

        }
        .ant-table-expanded-row > td:first-child,
        .ant-table-expanded-row .ant-table-expanded-row .ant-table-expanded-row > td:first-child {
            background-color: @normal-tbl-bg;

        }
    }

    .even > td {
        background-color: rgba(0,0,0,0.06);
        &.stripe-column {
            background-color: #e6ecf0 !important;
        }
    }

    .nested-details td {
        background-color: @detail-tbl-bg;
    }

    tr.ant-table-expanded-row .ant-table-wrapper {
        margin: 0 !important;
    }

    .ant-table-expanded-row {
        & > td:last-child, & > td:first-child {
            padding: 0 !important;
        }

        & > td:last-child .ant-table-thead th {
            border-bottom: 1px solid #e9e9e9;
        }
    }

    .ant-table-bordered .ant-table-body > table table {
        border: none;

        tr:last-child > td {
            border-bottom: none;
        }

        tr > td:last-child, tr > th:last-child {
            border-right: none;
        }
    }
}

.ant-tag {
    user-select: none;
}

.modal-selection.ant-select-dropdown {
    position: static;
    border: none;
    box-shadow: none;

    // .ant-select-dropdown-menu-item-selected {
    //     background-color: @background-color-base
    // }

    .ant-select-dropdown-menu {
        max-height: none;
    }
}

.modal-body-scroll {
    .ant-modal-body {
        overflow-y: scroll;
        min-height: 280px;
        max-height: 75vh;
    }
}

.ant-time-picker-panel-input {
    width: 100%;
}

.quantityWithUofM {
    color: unset;
    text-align: right
}

// .quantityWithUofM .ant-select-selection-selected-value {
//     text-decoration: underline;
//     color: @primary-color;
// }

.quantityWithUofM .ant-input-disabled {
    color: unset;
}

.quantityWithUofM input {
    color: unset;
    text-align: right;
    min-width: 75px;
}

.visibleDisabled {
    color: unset !important;
}

.visibleDisabled,
.ant-input[disabled],
.ant-select-disabled {
    color: rgba(0, 0, 0, 0.6) !important;
}

.visibleDisabled input {
    color: rgba(0, 0, 0, 0.6) !important;
}

.ant-checkbox-disabled + span {
    color: unset !important;
}

.inputWrapper {
    margin: 0;
    padding: 0
}

.inputWrapper .ant-form-item-control {
    line-height: 1.5; // Ant design default
}

.pointer {
    cursor: pointer;
}

.floatingAffix {
    background-color:rgba(255, 255, 255, 0.9);
    padding: 3px 8px;
    border-radius: 2px;
    height: 30px;
}

.helpText {
    font-size: .9em;
    color: gray;
}

.locker {
    display: inline-block;
}

.locker > *:not(:first-child) {
    margin-left: 15px;
    vertical-align: bottom;
}

// li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
//     color: @text-color-secondary;
// }

// .danger-background {
//     background: fade(@primary-color, 20%) !important;
// }

.danger-text {
    color: red;
}

.min-width-100 {
    min-width: 100px;
}

.max-width-100 {
    max-width: 100px;
}

.min-width-150 {
    min-width: 150px;
}

.max-width-150 {
    max-width: 150px;
}

.menu-buttons {
    float: right;
    margin-right: 6px;
}

.budget-summary {
    .ant-table {
    font-size:12px;
    }
}

// Placeholders on the right look silly -- bring them over to the left
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    text-align:left;
}
::-moz-placeholder { /* Firefox 19+ */
    text-align:left;
}
:-ms-input-placeholder { /* IE 10+ */
    text-align:left;
}
:-moz-placeholder { /* Firefox 18- */
    text-align:left;
}



.dashboard {
    background: white;
    // border-radius: 5px;
    //height: 100%;
    display: grid;


    .bin-visual {
        height: 100%;
    }

    // .summary {
    //     height: 100%;

    //     #player {
    //         min-height: 700px;
    //     }
    // }

    .bin-list {
        .ant-list-item {
            cursor: pointer;
        }
    }
}

.admin {
    background: white;
    > * {
        padding: 16px;
    }
}


.bin-visual {
    #player {
        overflow: hidden;
    }


    .bin-temperature {
        background-color: black;
        color: white;
        padding: 2px 5px;
        border-radius: 5px;
        opacity: 0.7;
        pointer-events: none !important;
    }
}

    // Extra small devices (portrait phones, less than 576px)
    // No media query since this is the default in Bootstrap
    @media (max-width: 575.98px) {
    /*.page-container {
        padding: 0 6px 6px;
    }

    .tab-page-container {
        padding: 0 6px;
    }

    .ant-breadcrumb {
        margin: 6px 0;
    } */
    .dashboard {
        background: white;
        // border-radius: 5px;
        //height: 100%;
        display: grid;
        width: 100%;
        > * {
            padding: 16px;
        }

        .bin-visual {
            height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }
    .dashboard-details {
        background: white;
        // border-radius: 5px;
        //height: 100%;

        > * {
            padding: 16px;
        }

        .bin-visual {
           height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
   /* .page-container {
        padding: 0 12px 12px;
    }

    .tab-page-container {
        padding: 0 12px;
    }

    .ant-breadcrumb {
        margin: 10px 0;
    }
    */
    .dashboard {
        background: white;
        // border-radius: 5px;
        //height: 100%;
        width: 100%;
        display: grid;
        > * {
            padding: 16px;
        }

        .bin-visual {
            height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }
    .dashboard-details {
        background: white;
        // border-radius: 5px;
        //height: 100%;
        width: 100%;

        > * {
            padding: 16px;
        }

        .bin-visual {
            height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .dashboard {
        background: white;
        // border-radius: 5px;
        //height: 100%;
        display: grid;
        > * {
            padding: 16px;
        }

        .bin-visual {
            height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }
    .dashboard-details {
        background: white;
        // border-radius: 5px;
        // height: 100%;

        > * {
            padding: 16px;
        }

        .bin-visual {
            height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .dashboard {
        background: white;
        // border-radius: 5px;
       // height: 100%;
        display: grid;
        > * {
            padding: 16px;
        }

        .bin-visual {
            height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }
    .dashboard-details {
        background: white;
        // border-radius: 5px;
        //height: 100%;

        > * {
            padding: 16px;
        }

        .bin-visual {
            height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .dashboard {
        background: white;
        // border-radius: 5px;
        //height: 100%;
        display: grid;
        > * {
            padding: 16px;
        }

        .bin-visual {

           flex-grow: 4;
           overflow: auto;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }
    .dashboard-details {
        background: white;
        // border-radius: 5px;
        //height: 100%;

        > * {
            padding: 16px;
        }
        .ant-row{
          justify-content: space-around;
        }
        .bin-visual {
            height: 100%;
        }

        // .summary {
        //     height: 100%;

        //     #player {
        //         min-height: 700px;
        //     }
        // }

        .bin-list {
            .ant-list-item {
                cursor: pointer;
            }
        }
    }

}
    // Extra large devices (large desktops)
    // No media query since the extra-large breakpoint has no upper bound on its width
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1199.98px) {
    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        .content-main,
        .content-pad {
            padding: 12px;
        }

        .content-head {
            padding: 12px 15px;
        }

        .content-container {
            padding: 12px;
        }

        .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane {
            padding: 0 12px 12px;
        }

        .ant-layout-header {
            height: 48px;
            line-height: 48px;
        }

        .ant-layout-header .trigger {
            line-height: 48px;
        }

        .ant-breadcrumb {
            margin: 10px 0;
        }

        .ant-layout-sider.ant-layout-sider-collapsed {
            min-width: 0 !important;
            max-width: 0 !important;
            width: 0 !important;
            flex: 0 !important;
        }

        // .ant-layout-sider-collapsed .ant-menu-inline-collapsed {
        //     width: 0;
        // }

        .ant-layout-sider.ant-layout-sider-collapsed li.ant-menu-item,
        .ant-layout-sider.ant-layout-sider-collapsed li.ant-menu-submenu {
            display: none;
        }

        .ant-modal {
            width: 95% !important;
        }

        .ant-modal-body {
            padding: 5px;
        }

        .layout-fullscreen {
            > .ant-layout-sider {
                > .ant-layout-sider-children {
                    > .logo-container {
                        width: 240px;
                    }

                    .scrollbar-sidenav {
                        top: 48px !important;
                    }

                    > .ant-menu {
                        top: 48px;
                    }
                }
            }

            > .ant-layout {
                > .ant-layout-header {
                    left: 195px;
                }

                > .ant-layout-content.page-content {
                    left: calc(195px + 12px);
                    right: 12px;
                    top: calc(48px + 12px);
                    bottom: 12px;

                    > .ant-layout.content-container {
                        > .ant-layout-content,
                        > .ant-spin-nested-loading > .ant-spin-container > .ant-layout-content {
                            max-height: calc(100vh - 105px + 17px + 10px);
                        }
                    }
                }
            }

            > .ant-layout-sider-collapsed {
                + .ant-layout {
                    > .ant-layout-header {
                        left: 0;
                    }

                    > .ant-layout-content {
                        left: 12px;
                    }
                }
            }
        }
    }
    // Extra small devices (portrait phones, less than 576px) (kinzler android tablets width = 601px
    @media (max-width: 605px) {
        .content-main,
        .content-pad {
            padding: 6px;
        }

        .content-head {
            padding: 6px 8px;
        }

        .content-container {
            padding: 12px 6px 6px 6px;
        }

        .ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content > .ant-tabs-tabpane {
            padding: 0 6px 6px;
        }

        .ant-breadcrumb {
            margin: 6px 0;
        }
    }


// logViewer.css

    .logViewContent {
        padding: 4px;
        background-color: #f0f0f0;
        white-space: pre;
        font-family: monospace;
        overflow: auto;
        max-height: 60ch;
        max-width: 200ch;
    }

    .legend-item {
        min-width: 200px;
    }
    .legend-cable-title {
        min-width: 128px;
    }

    .chart-range-picker {
        .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
            visibility: visible !important;
        }
        
        .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
            visibility: visible !important;
        }
        
        .ant-picker-panels > *:last-child {
            display: none !important;
        }
        
        .ant-picker-footer-extra > div {
            flex-wrap: wrap !important; 
        }
    }

    .ant-segmented-item .ant-segmented-item-selected {
        background-color: blue;
        color: white;
    }

    .mode-control-button {
        max-width: 116px !important;
        min-width: 80px !important;
        text-align: center !important;
        height: auto !important;
        white-space: normal !important;
    }

    .mode-control-button:hover {
        background-color: #00236612;
    }

    
:root {
    --fill-color: rgba(0, 0, 0, 0.75);
}

    // HMI table CSS

    .valve {
        text-align: center;
        border: 1px solid black;
        min-height: 38px;
        min-width: 38px;
        max-width: 38px;
        max-height: 38px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .valveSelected {
        background-color: lightskyblue;
    }
    
    .valveFailed {
        border: 3px solid red;
    }
    
    .stackArrowFailed {
        border: 2px solid red !important;
    }
    
    .layerArrowFailed {
        border: 2px solid red !important;
    }
    
    .table-pagination {
        display: flex;
        flex-direction: row;
    }
    
    .column-pagination {
        background-color: lightgreen;
    }
    
    .middle-pagination {
        /* background-color: burlywood; */
        width: 100%;
    }
    
    .row-pagination {
        /* background-color: lightgoldenrodyellow; */
        min-width: 150px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .valveDisabled {
        pointer-events: 'none';
        cursor: not-allowed;
        background-color: #F5F5F5;
    }
    
    
    .Closed {
        box-shadow: inset 0px 4px 0px 0px var(--fill-color),
                    inset 4px 0px 0px 0px var(--fill-color),
                    inset -4px 0px 0px 0px var(--fill-color);
    }
    
    .Flow {
        box-shadow: inset 4px 0px 0px 0px var(--fill-color),
                    inset -4px 0px 0px 0px var(--fill-color);
    }
    
    .Exhaust {
        box-shadow: inset 0px 0px 0px 0px var(--fill-color);
    }
    
    .Vent {
        box-shadow: inset 0px 4px 0px 0px var(--fill-color),
                    inset 0px 0px 0px 0px var(--fill-color),
                    inset 0px 0px 0px 0px var(--fill-color);
    }