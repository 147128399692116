.webGL-legend-scrollbar {
    width: 160px !important;
    //height: 800px !important;
}

.bin-legend-header {
    top: 0;

    .bin-select {
        margin-right: 10px;
    }

    > span {
        margin-right: 10px;
    }
}

.notificationSection {
    font-weight: bold;
    color: red;
}

.criticalNotificationSection {
    // font-weight: bold;
    // color: red;
}

.warningNotificationSection {
    // font-weight: bold;
    // color: orange;
}

.warningNotificationPanel {
    background-color: rgb(255, 223, 163);
}

.criticalNotificationPanel {
    background-color: rgb(248, 184, 184);
}

.warningNotificationPanel > .ant-collapse-header {
    // color: orange !important;
}

.criticalNotificationPanel > .ant-collapse-header {
    // color: red !important;
}

.site-collapse-custom-panel

.bin-temperature.selected {
    border: 2px solid yellow;
}

.webGL-legend {
    max-width: 220px;
    margin: 10px;

    > legend {
        margin-bottom: 0;
    }

    .ant-tree {
        font-size: 12px;
    }

    .ant-tree li {
        padding: 0;
    }

    .ant-tree li span.ant-tree-switcher {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }

    .ant-tree li .ant-tree-node-content-wrapper {
        height: 20px;
        line-height: 16px;
    }

    .ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
        width: 100%;
    }

    .ant-tree li ul {
        padding: 0 0 0 8px;
    }
    // .ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
    //     width: calc(100% - 8px);
    // }
    .ant-tree-switcher {
        display: none;
    }

    .color_square {
        border-radius: 4px;
        display: inline-block;
        margin: 0 5px;
        width: 15px;
        height: 12px;
    }

    .color_square.open {
        background-color: white;
        border: solid 1px black;
    }

    .color_square.closed {
        background-color: black;
    }

    .tree-switch {
        margin-right: 5px;
    }
}

#text {
    background-color: transparent; /* needed because webgl-tutoraisl.css sets canvas bg color to white */
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
    display: none;
}
