
// Make scrollbar always visible. Possible bug encountered if upgrading to antd 4.7.1. Fixed in some later version Jun 2021
// https://github.com/ant-design/ant-design/issues/30175
.ant-select-dropdown:not(.ant-select-dropdown-hidden) .rc-virtual-list-scrollbar {
  display: block !important; /* important to overwrite inline style display: none */
}

#body {
  margin: 0,
}

#body {
  .ant-modal-mask {
    background-color: #B2B2B2;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .hidden {
    display:none;
  }

  .ant-input-number {
    width: 100%
  }

  .right-align { 
    text-align: right;
    .ant-input-number-input {
      text-align: right;
    }
  }

  .flex-grow {
    flex-grow: 1;
  }

  .ant-input-number-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
  }

  .command-bar {
    margin-top: 1px;
    button, input{
      margin: 4px;
      &:last-child{
        margin-right: 5px;
      }
      &:first-child{
        margin-left: 5px;
      }
    }

  }
}


/*
Copied to match the table header color
*/
.ant-table-summary {
  background-color: #fafafa;
}


/*
Antd doesn't seen keen on on fixing gutters having unnecessary scrollars
https://github.com/ant-design/ant-design/issues/10144#issuecomment-1252424608
*/
.ant-row-flex, .ant-row { margin: 0 !important; }
