.ant-select-dropdown:not(.ant-select-dropdown-hidden) .rc-virtual-list-scrollbar {
  display: block !important;
  /* important to overwrite inline style display: none */
}
#body {
  margin: 0;
}
#body .ant-modal-mask {
  background-color: #B2B2B2;
  background-color: rgba(0, 0, 0, 0.3);
}
#body .hidden {
  display: none;
}
#body .ant-input-number {
  width: 100%;
}
#body .right-align {
  text-align: right;
}
#body .right-align .ant-input-number-input {
  text-align: right;
}
#body .flex-grow {
  flex-grow: 1;
}
#body .ant-input-number-input[disabled] {
  color: rgba(0, 0, 0, 0.65);
}
#body .command-bar {
  margin-top: 1px;
}
#body .command-bar button,
#body .command-bar input {
  margin: 4px;
}
#body .command-bar button:last-child,
#body .command-bar input:last-child {
  margin-right: 5px;
}
#body .command-bar button:first-child,
#body .command-bar input:first-child {
  margin-left: 5px;
}
/*
Copied to match the table header color
*/
.ant-table-summary {
  background-color: #fafafa;
}
/*
Antd doesn't seen keen on on fixing gutters having unnecessary scrollars
https://github.com/ant-design/ant-design/issues/10144#issuecomment-1252424608
*/
.ant-row-flex,
.ant-row {
  margin: 0 !important;
}
